<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入关键字"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-cascader
        class="filter-item"
        v-model="listQuery.vipId"
        placeholder="vip用户"
        :props="queryRolesProps"
        :options="queryRoleOptionList"
        style="width:300px"
        :show-all-levels="false"
        clearable
      ></el-cascader>
      <el-select
        v-model="listQuery.status"
        placeholder="状态"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option label="正常" :value="'0'" />
        <el-option label="冻结" :value="'1'" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
    </div>

    <div style="margin-bottom: 5px">用户数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="名称"
        align="center"
        prop="name"
      ></el-table-column>
      <el-table-column
        label="手机号码"
        align="center"
        prop="mobile"
      ></el-table-column>
      <el-table-column class-name="status-col" label="状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 0" type="primary">正常</el-tag>
          <el-tag v-else-if="scope.row.status === 1" type="warning"
            >冻结</el-tag
          >
          <el-tag v-else>后端无字段</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="创建时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="created_at" label="修改时间">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-popconfirm
            confirm-button-text="好的"
            cancel-button-text="不用了"
            icon="el-icon-info"
            icon-color="red"
            title="确定修改该用户状态吗？"
            @confirm="checkUserStatus(scope.row)"
            style="margin: 0 8px;"
          >
            <el-button slot="reference" type="success" size="mini"
              >切换用户状态</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { getUserList, switchUserStatus } from "@/api/user";
import { formatApiDate } from "@/utils/utils";

export default {
  computed: {
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    return {
      list: null,
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        keyword: "",
        vipId: "",
        status: ""
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };
      getUserList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 切换
     * @param row
     */
    checkUserStatus(row) {
      const params = {
        id: row.id,
        status: row.status === 0 ? 1 : 0
      };
      switchUserStatus(params).then(resp => {
        if (resp.code === 0) {
          this.$notify({
            title: "成功",
            message: resp.data,
            type: "success",
            duration: 2000
          });
          this.getList();
        } else {
          this.$notify({
            title: "提示",
            message: resp.data,
            type: "warning",
            duration: 2000
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
